import React from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import styles from "./layout.module.scss"
import { Link } from "gatsby"
import { IconButton } from "@material-ui/core"

export interface SiteMenuItemProps {
  name: string
  link?: string
}
interface SiteMenuProps {
 items: SiteMenuItemProps[]
}
interface SiteMenuState {
  anchorEl?: HTMLElement | null
}
class SiteMenu extends React.Component<SiteMenuProps,SiteMenuState> {
  constructor(props: SiteMenuProps) {
    super(props)
    this.state = {
      anchorEl: null,
    }
  }

  handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { anchorEl } = this.state;

    return (
      <div className={styles.siteMenu}>
        <IconButton
          classes={{root: styles.headerIcon}}
          aria-owns={anchorEl ? 'simple-menu' : undefined}
          aria-haspopup="true"
          onClick={this.handleClick}
        >☰
        </IconButton>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
        >
          {this.props.items.map(item =>
            <Link onClick={this.handleClose} to={item.link}>
              <MenuItem>{item.name}</MenuItem>
            </Link>
          )}
        </Menu>
      </div>
    );
  }
}

export default SiteMenu;
