import * as React from "react"
import { Link } from "gatsby"
import SiteMenu, { SiteMenuItemProps } from "./SiteMenu"
import iconFacebook from "../images/icon-facebook.svg"
import iconLinkedIn from "../images/icon-linkedin.svg"
import iconTwitter from "../images/icon-twitter.svg"
import styles from "./layout.module.scss"
import { IconButton } from "@material-ui/core"

interface HeaderProps {
  siteTitle: string
  menuItems: SiteMenuItemProps[]
}

const headerIconClasses = {root: styles.headerIcon}
const Header = ({ siteTitle, menuItems }: HeaderProps) => (
  <header>
    <SiteMenu items={menuItems}/>
      <IconButton classes={headerIconClasses} target={"_blank"} href='https://www.linkedin.com/in/syrjala/'><img alt='LinkedIn' className={styles.headerIcon} src={iconLinkedIn}/></IconButton>
      <IconButton classes={headerIconClasses} target={"_blank"}  href='https://twitter.com/JarSyr'><img alt='Twitter' className={styles.headerIcon} src={iconTwitter}/></IconButton>
      <IconButton classes={headerIconClasses} target={"_blank"}  href='https://www.facebook.com/jarkko.syrjala'><img alt='Facebook' className={styles.headerIcon} src={iconFacebook}/></IconButton>
    <div
      style={{
        margin: 0,
        paddingLeft: '1.0875rem',
        paddingBottom: '2rem',
      }}
    >
      <h1 style={{ margin: 0, fontWeight:100, lineHeight: '0.9em' }}>
        <Link
          to="/"
          style={{
            color: `white`,
            textDecoration: `none`,
          }}
        >
          {siteTitle}
        </Link>
      </h1>
    </div>
  </header>
)

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
